import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import H2 from 'components/typography/h2'
import { Helmet } from 'react-helmet'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { FaFire } from '@react-icons/all-files/fa/FaFire'
import { FaCloud } from '@react-icons/all-files/fa/FaCloud'
import { FaCompress } from '@react-icons/all-files/fa/FaCompress'
import { FaBolt } from '@react-icons/all-files/fa/FaBolt'
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle'
import { FaFlag } from '@react-icons/all-files/fa/FaFlag'
import { FaBullseye } from '@react-icons/all-files/fa/FaBullseye'
import { FaUmbrella } from '@react-icons/all-files/fa/FaUmbrella'
import { FaUndoAlt } from '@react-icons/all-files/fa/FaUndoAlt'
import { FaAmbulance } from '@react-icons/all-files/fa/FaAmbulance'
import { FaBan } from '@react-icons/all-files/fa/FaBan'
import { FaLock } from '@react-icons/all-files/fa/FaLock'
import Layout from 'components/layout'
import Content from 'components/content'

const SentryPage = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Sentry Insurance | Hutson Inc</title>
        <meta
          name='description'
          content='Farmers nationwide rely on Sentry Insurance every day. Find out why Sentry is the largest writer of John Deere dealerships.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Sentry Insurance',
                'item': 'https://www.hutsoninc.com/sentry/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroImage title='Sentry Insurance' image={heroImage} />

      <Content>
        <H2>Sentry Insurance</H2>
        <H3>Reasons customers choose UltraGuard&reg; and SentryGuard&trade; Insurance:</H3>
        <ul>
          <li>Repairs made using genuine John Deere parts</li>
          <li>Satisfies John Deere Financial insurance requirements on UltraGard</li>
          <li>Premium for UltraGard&reg; can be built into the loan payment</li>
          <li>Easy and convenient payment options</li>
          <li>
            Fast, fair claims handling by professionals who know and understand your equipment
          </li>
          <li>Dealerships can report claims to minimize customer down time</li>
          <li>
            Limits may need to be adjusted on your blanket at additional costs to be properly
            insured
          </li>
        </ul>

        <Section>
          <H3>Equipment Physical Damage Insurance with Sentry Insurance</H3>
          <p>
            UltraGard&reg; and SentryGuard&trade; are immediate, no-hassle, physical damage coverage
            programs. Offered at very competitive rates, these programs provide coverage for:
          </p>
          <IconsContainer>
            <div>
              <FaFire aria-hidden='true' focusable='false' role='presentation' />
              <p>Fire</p>
            </div>
            <div>
              <FaCloud aria-hidden='true' focusable='false' role='presentation' />
              <p>Hail</p>
            </div>
            <div>
              <FaCompress aria-hidden='true' focusable='false' role='presentation' />
              <p>Collision</p>
            </div>
            <div>
              <FaBolt aria-hidden='true' focusable='false' role='presentation' />
              <p>Lightning</p>
            </div>
            <div>
              <FaExclamationTriangle aria-hidden='true' focusable='false' role='presentation' />
              <p>Tornado</p>
            </div>
            <div>
              <FaFlag aria-hidden='true' focusable='false' role='presentation' />
              <p>Wind</p>
            </div>
            <div>
              <FaBullseye aria-hidden='true' focusable='false' role='presentation' />
              <p>Earthquake</p>
            </div>
            <div>
              <FaUmbrella aria-hidden='true' focusable='false' role='presentation' />
              <p>Flood</p>
            </div>
            <div>
              <FaUndoAlt aria-hidden='true' focusable='false' role='presentation' />
              <p>Overturn</p>
            </div>
            <div>
              <FaAmbulance aria-hidden='true' focusable='false' role='presentation' />
              <p>Vandalism</p>
            </div>
            <div>
              <FaBan aria-hidden='true' focusable='false' role='presentation' />
              <p>Rock Ingestion</p>
            </div>
            <div>
              <FaLock aria-hidden='true' focusable='false' role='presentation' />
              <p>Theft</p>
            </div>
          </IconsContainer>
        </Section>
      </Content>
    </Layout>
  )
}

const Section = styled.div`
  margin: 40px 0 0;

  p {
    margin: 0;
  }

  @media (min-width: 800px) {
    margin: 80px 0 0;
  }
`

const H3 = styled.h3`
  font-size: 1.25em;
  font-weight: bold;
  margin: 0;
  text-transform: none;
`

const IconsContainer = styled.div`
  ${clearfix}
  margin: 0 auto;

  > div {
    ${column('1/3')}
    margin-top: 20px;
    text-align: center;
  }

  svg {
    width: 3em;
    height: 3em;
  }

  p {
    display: block;
    margin-top: 5px;
    font-size: 0.82em;
  }

  @media (min-width: 800px) {
    margin: 0 auto;

    > div {
      ${column('1/6')}
      margin-top: 40px;
    }
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "sentry/sentry-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default SentryPage
